<template>
    <div>
        <!-- General information -->
        <card title="General information" body-class="overflow-visible" class="carrier-edit-general border-radius" title-no-border>
            <div slot="tools">
                <div class="flex">
                    <button class="btn-transparent block" @click="redirectToIndex()">Cancel</button>
                    <button class="btn-primary block ml-4" @click="save()">Save</button>
                </div>
            </div>

            <div class="w-full">
                <div class="flex w-full flex-wrap">

                    <div class="w-full flex flex-wrap">
                        <div class="w-1/3 pr-4">
                            <t-input label="Name" v-model="carrier.name" :error="validationErrors.name" class="" />
                        </div>

                        <div class="w-1/3">
                            <modal-field label="Name variations">
                                <multi-select
                                    v-model="carrier.variations"
                                    :branding="false"
                                    :dynamic="true"
                                    :multiple="true"
                                    :options="[]">
                                </multi-select>
                            </modal-field>
                        </div>

                        <div class="w__100-p mt-4">
                            <div class="">
                                <div class="relative flex flex-wrap">
                                    <modal-field label="Logotype" class="flex">
                                        <label
                                            class="inline-block cursor-pointer btn-primary"
                                            for="file">
                                            Select file
                                        </label>
                                        <input
                                            class="hidden"
                                            id="file"
                                            type="file"
                                            name="file"
                                            accept="jpg/image/jpeg/image/png"
                                            @change="handleFile"
                                        />
                                    </modal-field>

                                    <span class="flex items-end w-full px-2 ml-2 text-gray-500 border-b file-info ml-auto" style="width: calc(100% - 110px)" :class="{'border-b border-red-500' : validationErrors.image}">
                                    <span v-if="! media.fileInput">No file has been uploaded yet</span>
                                    <span class="text-gray-800" v-else>{{ media.fileInput }}</span>
                                </span>

                                    <transition name="fade">
                                    <span v-if="validationErrors.image"
                                          class="absolute bottom-0 w-full -mb-6 text-red-500 help-block">
                                        <span v-if="validationErrors.image">{{ validationErrors.image[0] }}</span>
                                    </span>
                                    </transition>
                                </div>

                                <div class="flex w-full mt-4">
                                <span v-if="media.previewImage != null" class="flex block w-full">
                                    <img :src="media.previewImage"
                                         class="uploading-image"
                                         style="max-height: 47px;"
                                         :class="{'mt-4'  : validationErrors.image != null}">
                                </span>

                                    <span v-if="carrier.media != null && media.previewImage == null" class="flex block w-full">
                                    <img :src="getLogoUrl(carrier.media.url)" class="uploading-image" style="max-height: 47px;">
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </card>

        <!-- Services -->
        <carrier-services v-if="editing" :carrier="carrier" />
    </div>
</template>

<script>

import axios from 'axios';
import PageHeader from "~/components/PageHeader";
import ModalField from "~/components/ModalField";
import MultiSelect from "~/components/MultiSelect";
import CarrierServices from "./carrier-services/Index";

const formatServices = (services) => {
    // tagged multiselect in ./Index.vue
    // requires options to have isTag property
    services.forEach(service => {
        service.tags = service.tags.map(tag => ({ label: tag, isTag: true }));
        service.tagsOptions = [...service.tags];
    });
};

export default {

    name: 'CarrierEdit',

    components: {CarrierServices, MultiSelect, ModalField, PageHeader},

    metaInfo() {
        return {title: 'Carrier'}
    },

    data: () => ({
        carrier: {
            name: '',
            code: '',
            consignor_code: '',
            consignor_code_dutiable: '',
            variations: [],
            services: [],
        },
        media: {
            logo: null,
            fileInput: null,
            previewImage: null,
        },
        validationErrors: {},
    }),

    created() {
        if (this.editing) {
            this.fetch();
        }
    },

    computed: {
        carrierId() {
            return this.$route.params.id;
        },

        editing() {
            return !!this.carrierId;
        },
    },

    methods: {
        async fetch(id) {
            const { data: { data } } = await axios.get(`${this.$apiUrl.carriers.base}/${id || this.carrierId}?with=services,media`);

            formatServices(data.services);

            this.carrier = data;
        },

        save() {
            this.validationErrors = {}
            this.editing ? this.update() : this.store();
        },

        store() {
            const formData = this.buildForm();

            axios.post(this.$apiUrl.carriers.base, formData).then((response) => {
                this.$snotify.success('Carrier created!');
                this.$router.push({ name: 'admin.carriers.edit', params: {id: response.data.data.id}});
                this.fetch(response.data.data.id);
            }).catch((error) => {
                if (error.response.data.errors) {
                    this.$snotify.warning('Please check your values');
                    this.validationErrors = error.response.data.errors;
                }
            })
        },

        redirectToIndex() {
            this.$router.push({ name: 'admin.carriers'});
        },

        update() {
            const formData = this.buildForm();

            if (this.media.logo) {
                formData.append('logo', this.media.logo);
            }

            axios.post(`${this.$apiUrl.carriers.base}/${this.carrier.id}`, formData).then((response) => {
                this.carrier = response.data.data;
                this.$snotify.success('Carrier updated!');
            }).catch((error) => {
                if (error.response.data.errors) {
                    this.$snotify.warning('Please check your values');
                    this.validationErrors = error.response.data.errors;
                }
            })
        },

        buildForm() {
            const filteredCarrier = this.cloneObject(this.carrier);
            delete filteredCarrier.services

            const formData = this.objectToFormData(filteredCarrier);

            if (this.media.logo) {
                formData.append('logo', this.media.logo);
            }

            return formData;
        },

        handleFile(file) {
            this.media.fileInput = file.target.files[0].name;

            const image = file.target.files[0];
            this.media.logo = file.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = file =>{
                this.media.previewImage = file.target.result;
            };
        },

        getLogoUrl(file) {
            return file;
        },
    }
}
</script>

<style scoped>
.uploading-image {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
</style>

<style lang="scss">
.carrier-edit-general {
    .card-body {
        padding-bottom: 0 !important;
    }
}
</style>
